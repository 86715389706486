import React from 'react';
import cn from 'classnames';
import styles from '../InteractiveCard/InteractiveCard.module.scss';
import Skeleton from './Skeleton';

const InteractiveCardSkeleton = () => {
	const containerClasses = cn(styles.container, styles['container--flexible']);
	return (
		<div className={containerClasses} style={{ background: 'white' }}>
			<Skeleton width={'100%'} height={'100%'} type='rect' borderRadius={'1rem'} />
		</div>
	);
};

export default InteractiveCardSkeleton;
