import { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { BASE_FRAME_NAMES } from '@constants';
import { AGE_RANGES, SHAPE_OPTIONS, WIDTH_OPTIONS } from '@utils/constants/collections';
import { Flex, LabelText } from '@components';
import { FilterControlProps } from '@ts/index';
import styles from './PreSelectedFilter.module.scss';
interface PreSelectedFilterProps {
	title?: string;
	subtitle?: string;
	withFrameShape?: boolean;
	withFilter?: boolean;
	filterControl?: FilterControlProps;
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	allSelected: boolean;
	setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
	heroRef: MutableRefObject<HTMLDivElement>;
	pathInfo: string;
}

type TagProps = {
	name: string;
	callback: () => void;
	isActiveStyle: boolean;
};

const Tag = ({ name, callback, isActiveStyle }: TagProps) => {
	return (
		<div className={`${styles.shapeContainer} ${isActiveStyle ? styles.active : ''}`} onClick={callback}>
			<LabelText color='#4B4849'>{name}</LabelText>
		</div>
	);
};

const PreSelectedFilter: FC<PreSelectedFilterProps> = ({ filterControl, allSelected, setAllSelected, heroRef, pathInfo }) => {
	const {
		asPath,
		push,
		query: { demo },
	} = useRouter();
	const isBrowser = () => typeof window !== 'undefined';
	const { filters, setFilters, triggerFilter, setFiltersActive, filtersActive } = filterControl;
	const containerRef = useRef<HTMLDivElement>();
	const filterParams = asPath.split('?')[1];

	const scrollToTop = useCallback(() => {
		if (!isBrowser()) return;
		window.scrollTo({ top: heroRef.current.offsetHeight, behavior: 'smooth' });
	}, []);

	const handleFilterClick = useCallback(
		(category: string, value: string) => {
			const valueApplied = filters[category]?.includes(value);
			if (category === 'shape') {
				value = value === 'Rectangular' ? 'square' : value.toLowerCase();
			}
			const actionType = valueApplied ? `remove-${category}` : `add-${category}`;
			setFilters({ type: actionType, value });

			if (actionType.includes('remove')) {
				setAllSelected(true);
			} else {
				setAllSelected(false);
			}
			scrollToTop();
		},
		[filters, setFilters]
	);

	useEffect(() => {
		if (!!filterParams) {
			setAllSelected(false);
			setFiltersActive(true);
		} else {
			setAllSelected(true);
			setFiltersActive(false);
		}
	}, []);

	useEffect(() => {
		triggerFilter(true);
		if (!allSelected || filterParams) {
			setFiltersActive(true);
		} else {
			setFiltersActive(false);
		}
	}, [allSelected, filterParams]);

	useEffect(() => {
		if (filtersActive) {
			const query = new URLSearchParams(filterParams);
			Object.keys(filters).forEach(key => {
				if (filters[key].length > 0) {
					query.set(key, filters[key][0]);
				} else {
					query.delete(key);
				}
			});

			const newUrl = query.toString() ? `${pathInfo}?${query.toString()}` : pathInfo;
			push(newUrl, undefined, { shallow: true });
		} else {
			push(pathInfo, undefined, { shallow: true });
		}
	}, [filters, filtersActive]);

	const kidsPLP = demo === 'kids';
	const mensPLP = demo === 'men';
	const sizeOptions = kidsPLP ? AGE_RANGES : WIDTH_OPTIONS;

	return (
		<>
			<div ref={containerRef} className={styles.optionsWrapper} data-filter-tag>
				<Flex gap={3} className={styles.optionsContainer}>
					<Tag
						name='All'
						isActiveStyle={allSelected}
						callback={() => {
							setFilters({ type: 'reset', value: '' });
							setFiltersActive(false);
							triggerFilter(false);
							setAllSelected(true);
							scrollToTop();
						}}
					/>
					<Tag
						name='Mixed Materials'
						isActiveStyle={filters.base?.length > 0}
						callback={() => {
							handleFilterClick('base', 'Metal');
						}}
					/>
					{SHAPE_OPTIONS.map(s => {
						if (s === 'Cat-Eye' && (mensPLP || kidsPLP)) {
							return null;
						}
						return (
							<Tag
								key={`preFilter-${s}`}
								name={s}
								isActiveStyle={filters.shape.includes(s === 'Rectangular' ? 'square' : s.toLowerCase())}
								callback={() => {
									handleFilterClick('shape', s === 'Rectangular' ? 'square' : s.toLowerCase());
								}}
							/>
						);
					})}
					{sizeOptions.map(w => (
						<Tag
							key={`preFilter-${w}`}
							name={w}
							isActiveStyle={filters.width.includes(w)}
							callback={() => {
								handleFilterClick('width', w);
							}}
						/>
					))}
				</Flex>
			</div>
		</>
	);
};

export default PreSelectedFilter;
