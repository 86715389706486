import React from 'react';
import cn from 'classnames';
import styles from '../../contentful/Hero/Hero.module.scss';
import Skeleton from './Skeleton';

type HeroSkeletonProps = {
	type: 'Default' | 'Plp' | 'Compact' | 'Full-Bleed' | 'Full-Bleed-Plp' | 'Video' | 'Center-Content';
	asBanner?: boolean;
	size?: 'small' | 'medium' | 'responsive';
};

const HeroSkeleton = ({ type, asBanner = false, size = 'medium' }: HeroSkeletonProps) => {
	const heroClasses = cn(styles['hero'], {
		[styles['default']]: type === 'Default',
		[styles['plp']]: type === 'Plp',
		[styles['compact']]: type === 'Compact',
		[styles['full-bleed']]: type === 'Full-Bleed' || type === 'Center-Content',
		[styles['full-bleed-plp']]: type === 'Full-Bleed-Plp',
		[styles['banner-medium']]: asBanner && size === 'medium',
		[styles['banner-small']]: asBanner && size === 'small',
		[styles['banner-responsive']]: asBanner && size === 'responsive',
		[styles['video']]: type === 'Video',
	});
	return (
		<div className={heroClasses}>
			<Skeleton width={'100%'} height={'100%'} type='rect' borderRadius={'1rem'} />
		</div>
	);
};

export default HeroSkeleton;
